.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.loader {
  display: flex;
  align-items: center;
}

.loadingText {
  position: absolute;
  width: 100%;
  top: 60px;
  text-align: center;
}

.mindful-dayScaleCell {
  cursor: pointer;
}